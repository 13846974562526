import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Description from '../../components/paragraphs/description';
import Headings from '../../components/paragraphs/headings';

const Cours = ({
  id,
  title,
  description,
  imgPresentation,
  icon,
  imgDesc,
  color,
  paragraphs,
  days,
  ateliers,
  nota_bene,
  images,
  detailsTarif,
  children,
  meta,
  pageContext,
}) => {
  const logoStyle = {
    objectFit: 'cover',
    width: 'auto',
    height: '150px',
  };
  const imgPresentationStyle = {
    objectFit: 'cover',
    width: 'auto',
    height: '150px',
    border: '5px',
    color,
    borderRadius: '5px',
    borderStyle: 'solid',
  };
  const dayList = days.map((day) => day.jour);

  return (
    <Layout title={meta.title} buildTime={pageContext.buildTime}>
      <SEO meta={meta} />

      <Headings
        title={title}
        imgPresentation={imgPresentation}
        icon={icon}
        color={color}
        paragraphs={paragraphs}
        dayList={dayList}
      />

      <Description
        id={id}
        title={title}
        description={description}
        imgDesc={imgDesc}
        days={days}
        ateliers={ateliers}
        color={color}
        anchor="description_des_ateliers"
        detailsTarif={detailsTarif}
      />
      {children}


    </Layout>
  );
};

export default Cours;
